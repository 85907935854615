import styled from "styled-components";
import { Main } from "../../../core/layout/view/desktop/main.elements";

export const ConnexionMain = styled(Main)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0;
  width: 100%;
`;

export const IFrame = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;
