import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { H1 } from "../../../../core/layout/view/mobile/title.elements";
import { ButtonWithIcon } from "../../../../core/layout/button/button.elements";
import { exportCSV } from "../../exportCSV";

const HeaderWrapper = styled.div`
  position: relative;
  padding: 20px 20px 40px;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid ${(props) => props.theme.border.dark};
`;

const Resultat = styled.div`
  position: absolute;
  top: 0px;
  right: 20px;
  line-height: 60px;
  font-size: 20px;
`;

export const BtnTelecharger = styled(ButtonWithIcon)`
  margin: 0 0 0 20px !important;
`;

interface HeaderProps {
  iResultat: number | undefined;
  bLoading: boolean;
  tResultat: any;
  config: any;
}

const Header: FunctionComponent<HeaderProps> = ({
  iResultat,
  bLoading,
  tResultat,
  config,
}): JSX.Element | null => {
  const handleExportClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    exportCSV(tResultat, config);
  };

  return (
    <HeaderWrapper>
      <H1>Télésuivi PPC</H1>

      {!bLoading && iResultat !== undefined && (
        <Resultat>
          {`${iResultat} résultat${iResultat > 1 ? "s" : ""}`}

          <BtnTelecharger
            sLabel="Télécharger"
            sIconName="download"
            onClick={handleExportClick}
          />
        </Resultat>
      )}
    </HeaderWrapper>
  );
};
export default Header;
