import React, { FunctionComponent } from "react";

import * as FormElt from "../../../../../../../core/layout/form/form.elements";

interface Prescripteur {
  iPKAnnuaire: number;
  sNom: string;
  sPrenom: string;
}

interface PrescripteursProps {
  tPrescripteur: Prescripteur[];
  setPrescripteursSelection: (tPrescripteursSelection: number[]) => void;
}

interface PrescripteursOption {
  key: number;
  value: number;
  text: string;
}

export const Prescripteurs: FunctionComponent<PrescripteursProps> = ({
  tPrescripteur,
  setPrescripteursSelection,
}) => {
  const options: PrescripteursOption[] = [];

  tPrescripteur.forEach((p: Prescripteur) => {
    options.push({
      key: p.iPKAnnuaire,
      value: p.iPKAnnuaire,
      text: `${p.sNom} ${p.sPrenom}`,
    });
  });

  return (
    <FormElt.Field>
      <FormElt.FieldLabel>
        <FormElt.Label>Prescripteurs :</FormElt.Label>
      </FormElt.FieldLabel>
      <FormElt.FieldContent>
        <FormElt.Droplist
          fluid
          multiple
          search
          selection
          placeholder={
            tPrescripteur.length === 0
              ? "Chargement des options..."
              : "Sélectionnez les prescripteurs"
          }
          options={options}
          onChange={(evt: any, data: any) =>
            setPrescripteursSelection(data.value)
          }
          disabled={tPrescripteur.length === 0}
        />
      </FormElt.FieldContent>
    </FormElt.Field>
  );
};
