import * as React from "react";
import { Switch, useLocation, useHistory } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

export interface Props {
  children: JSX.Element[] | JSX.Element;
}

const AnimatedSwitch = (props: Props) => {
  const location = useLocation();
  const history = useHistory();

  if (location.pathname === "/utilisateur/connexion" && history.action !== "PUSH") {
    return (
      <Switch location={location} key={location.pathname}>
        {props.children}
      </Switch>
    );
  }

  return (
    <AnimatePresence
      exitBeforeEnter={false}
      initial={false}
      // the exit animation needs the updated history to know if it's a push or pop
      custom={{ action: history.action }}
    >
      <Switch location={location} key={location.pathname}>
        {props.children}
      </Switch>
    </AnimatePresence>
  );
};

export default AnimatedSwitch;