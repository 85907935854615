import React, { FunctionComponent } from "react";

import * as FormElt from "../../../../../../../core/layout/form/form.elements";

interface Fournisseur {
  iPKAnnuaire: number;
  sNom: string;
}

interface FournisseursProps {
  tFournisseur: Fournisseur[];
  setFournisseursSelection: (tFournisseurSelection: number[]) => void;
}

interface FournisseursOption {
  key: number;
  value: number;
  text: string;
}

export const Fournisseurs: FunctionComponent<FournisseursProps> = ({
  tFournisseur,
  setFournisseursSelection,
}) => {
  const options: FournisseursOption[] = [];

  tFournisseur.forEach((f: Fournisseur) => {
    options.push({ key: f.iPKAnnuaire, value: f.iPKAnnuaire, text: f.sNom });
  });

  return (
    <FormElt.Field>
      <FormElt.FieldLabel>
        <FormElt.Label>Fournisseurs :</FormElt.Label>
      </FormElt.FieldLabel>
      <FormElt.FieldContent>
        <FormElt.Droplist
          fluid
          multiple
          search
          selection
          placeholder={
            tFournisseur.length === 0
              ? "Chargement des options..."
              : "Sélectionnez les fournisseurs"
          }
          options={options}
          onChange={(evt: any, data: any) =>
            setFournisseursSelection(data.value)
          }
          disabled={tFournisseur.length === 0}
        />
      </FormElt.FieldContent>
    </FormElt.Field>
  );
};
