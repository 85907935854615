import { combineReducers, configureStore } from "@reduxjs/toolkit";

import appReducer from "./redux/app/app.slice";
import utilisateurReducer from "./redux/utilisateur/utilisateur.slice";
import tableReducer from "./redux/table/table.slice";

const reducer = combineReducers({
  app: appReducer,
  utilisateur: utilisateurReducer,
  table: tableReducer,
});

export default configureStore({
  reducer,
});
