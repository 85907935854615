import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";
import { Table } from "../../interfaces/table.interface";

const initialState:Table = {
    sEntry:{}
}

export const slice = createSlice({
    name: "table",
    initialState,
    reducers: {
        openEntryPage: (state, { payload }: PayloadAction<Table>) => {
            state.sEntry = payload.sEntry
        }
    }
});

export const { openEntryPage } = slice.actions;
export const selectEntry = (state: Store) => state.table.sEntry;

export default slice.reducer;