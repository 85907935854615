import React, { FunctionComponent } from "react";
import TopBar from "../../../core/layout/view/desktop/topBar.elements";

interface TopProps {}

const Top: FunctionComponent<TopProps> = () => {
  return <TopBar/>;
};

export default Top;
