// @ts-nocheck
import axios from "axios";

import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import { Identite } from "../../../interfaces/utilisateur.interface";
import { connexionSucces, connexionErreur } from "../utilisateur.slice";

import config from "../../../config";

function getCookie(name: string) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

export const authentificationAction =
  (): ThunkAction<void, Identite, unknown, Action<string>> =>
  async (dispatch) => {
    const token = getCookie("Authorization");

    if (token) {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        const response = await axios(`${config.serverURL}/auth/verify`, {
          method: "POST",
          data: { token },
          withCredentials: true,
        });

        const {
          data: { user },
        } = response;

        if (user) {
          document.cookie = `Authorization=${token};path=/;secure;SameSite=strict`;

          dispatch(connexionSucces({ user: user as Identite, token }));
        }

        throw Error("User not found");
      } catch {
        dispatch(connexionErreur({ sErreur: "Token invalide" }));
      }
    }
  };
