import React, { FunctionComponent } from "react";

import AnimatedView from "../../core/layout/view/mobile/view.elements";

import { Header } from "semantic-ui-react";

import Layout from "../../core/layout/view/mobile/layout.elements";
import Left from "../elements/left/left.elements";
import Right from "../elements/right/right.elements";
import Main from "../elements/main/main.elements";
import { H1 } from "../../core/layout/view/mobile/title.elements";

const Error404View: FunctionComponent = () => {
  return (
    <AnimatedView view={`Error 404`}>
      <Layout
        header={<Header>Erreur 404 : </Header>}
        left={<Left />}
        right={<Right />}
        main={
          <Main>
            <H1>Cette page n'existe pas</H1>
          </Main>
        }
      />
    </AnimatedView>
  );
};

export default Error404View;
