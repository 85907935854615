import { createGlobalStyle } from "styled-components";

import UniversCondensed from "../fonts/Univers-Condensed.otf";
import UniversCondensedBold from "../fonts/Univers-Condensed-Bold.otf";
import Futura from "../fonts/Futura-LT-Book.otf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "UniversCondensed";
    src: url(${UniversCondensed});
  }

  @font-face {
    font-family: "UniversCondensedBold";
    src: url(${UniversCondensedBold});
  }

  @font-face {
    font-family: "Futura";
    src: url(${Futura});
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
  }

  html {
    margin: 0;
    padding: 0;
    background: #fff; /* ${(props) => props.theme.text.primary}; */
  }

  body {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "UniversCondensed", sans-serif !important;
    font-size: 14px;
    line-height: 1.2857142857142857142857142857143em;
    color: ${(props) => props.theme.text.primary} !important;
    background: #fff; /*  ${(props) => props.theme.background.light}; */
    /* overscroll-behavior: none; */
  }

  input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    background: ${(props) => props.theme.colors.white};
  }
  input[type=checkbox]:checked {
    background: ${(props) => props.theme.colors.primary.main};
  }
  input[type=checkbox]:checked::after {
    content: "X";
    position: absolute;
    left: 3px;
    top: 0px;
    font-size: 17px;
    color: ${(props) => props.theme.colors.white};
  }

  svg {
    fill: currentColor;
  }

  main {
    background: ${(props) => props.theme.background.light};
    min-height: 100vh;
  }
  
  button {
    font-family: "UniversCondensed", sans-serif !important;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
  }

  /*
  .ui.selection.dropdown {
    padding: 0 10px;
    min-height: 35px;
    line-height: 35px;
  }
  */

  body > iframe {
    display: none;
    z-index: -1 !important;
  }

  .ui.dropdown .menu>.item.default {
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary.main};
  }
  .ui.dropdown>.text {
    width: 100%;
  }

  .loading {
    animation: loading-opacity infinite 1s ease-in-out;
  }

  @media(max-width: 1366px) {
    body {
      font-size: 12px !important;
      line-height: 1.2857142857142857142857142857143em;
    }
  }

  @keyframes App-logo-float {
    0% {
      transform-origin: center;
      transform: translateY(0) rotate(0);
    }
    50% {      
      transform-origin: center;
      transform: translateY(5px) rotate(6deg);
    }
    100% {
      transform-origin: center;
      transform: translateY(0px) rotate(0);
    }
  }

  @keyframes loading-opacity {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default GlobalStyle;
