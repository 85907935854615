import { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { ConnexionMain, IFrame } from "./connexion.elements";
import { connexionAction } from "../../../../redux/utilisateur/actions/connexion.action";
import { selectConnecte } from "../../../../redux/utilisateur/utilisateur.slice";
import AnimatedViewDesktop from "../../../core/layout/view/desktop/view.elements";
import config from "../../../../config";

const ConnexionView: FunctionComponent = () => {
  const dispatch = useDispatch();
  const bConnecte = useSelector(selectConnecte);

  useEffect(() => {
    const getToken = (evt: MessageEvent) => {
      const {
        origin,
        data: { token },
      } = evt;

      if (origin === config.identityServerURL) {
        dispatch(connexionAction(token));
      }
    };

    window.addEventListener("message", getToken, false);

    return () => window.removeEventListener("message", getToken);
  });

  if (bConnecte) {
    return <Redirect to="/" />;
  }

  return (
    <AnimatedViewDesktop view="Connexion">
      <ConnexionMain>
        <IFrame
          src={`${config.identityServerURL}/login?src=${window.location.origin}${window.location.pathname}?theme=${config.theme}`}
        />
      </ConnexionMain>
    </AnimatedViewDesktop>
  );
};

export default ConnexionView;
