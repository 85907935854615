import React, { useEffect, useState } from "react";
import { GridReadyEvent } from "@ag-grid-community/all-modules";

import AnimatedViewDesktop from "../../core/layout/view/desktop/view.elements";
import Layout from "../../core/layout/view/desktop/layout.elements";
import TopBar from "../elements/top/top.elements";
import Header from "./elements/header/header.elements";
import Left from "./elements/left/left.elements";
import Main from "./elements/main/main.elements";

import ContextMenu from "./elements/main/elements/context.elements";

import type { ResultatRecherche } from "../../../interfaces/recherche.interface";
import { config } from "./config";

export interface Context {
  bVisible: boolean;
  config?: any;
  iPKAnnuaire: number;
  tResultat?: any;
  x: number;
  y: number;
}

export default function IndexView() {
  const [autoResize, setAutoResize] = useState<GridReadyEvent>();
  const [bLoading, setLoading] = useState<boolean>(false);
  const [tResultat, setResultats] = useState<ResultatRecherche[] | undefined>();

  const initialContext: Context = {
    bVisible: false,
    iPKAnnuaire: 0,
    x: 0,
    y: 0,
  };

  const [context, setContext] = useState<Context>(initialContext);

  const descendantOf = (element: any, ancestor: any) => {
    if (!element || !ancestor) return false;

    let newElement = element;

    while (newElement) {
      newElement = newElement.parentNode;
      if (newElement === ancestor) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const handleBodyClick = (e: any) => {
      const contextMenuElement = document.querySelector(".context-menu");

      if (!descendantOf(e.target, contextMenuElement)) {
        setContext(initialContext);
      } else {
        e.stopPropagation();
        setTimeout(() => {
          setContext(initialContext);
        }, 250);
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  });

  const setAutoResizeFunction = (event: GridReadyEvent) => {
    setAutoResize(event);
  };

  const onLeft = () => {
    if (autoResize !== undefined && autoResize !== null) {
      setTimeout(() => {
        autoResize.api.sizeColumnsToFit();
      }, 300);
    }
  };

  return (
    <AnimatedViewDesktop view="Accueil">
      <Layout
        onLeft={onLeft}
        topBar={<TopBar />}
        header={
          <Header
            tResultat={tResultat}
            config={config}
            iResultat={tResultat?.length}
            bLoading={bLoading}
          />
        }
        left={
          <Left
            setLoading={setLoading}
            bLoading={bLoading}
            setResultats={setResultats}
          />
        }
        leftOpen
        main={
          <Main
            setAutoResizeFunction={setAutoResizeFunction}
            bLoading={bLoading}
            tResultat={tResultat}
            setContext={setContext}
          />
        }
      />
      <ContextMenu context={context} setContext={setContext} />
    </AnimatedViewDesktop>
  );
}
