import React, { FunctionComponent } from "react";
import { Section } from "../../../core/layout/view/elements/section.elements";

interface RightProps {}

const Right: FunctionComponent<RightProps> = () => {
  return <Section>Right Panel</Section>;
};

export default Right;
