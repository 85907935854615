import styled from "styled-components";

export const H1 = styled.h1`
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.secondary.main};
`;

export const H2 = styled.h2`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary.main};
  font-weight: bold;
  font-size: 14px;
  margin: 1vmin;
`;
