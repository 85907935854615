import React, { FunctionComponent, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import * as FormElt from "../../../../../../../core/layout/form/form.elements";

interface Modele {
  iPKAnnuaire: number;
  iPKProduit: number;
  sLibelle: string;
  sNom: string;
  sRefProduit: string;
}
interface ModelesProps {
  tModele: Modele[];
  tFournisseurSelection: number[];
  setModelesSelection: (tModeleSelection: number[]) => void;
}

interface ModelesOption {
  key: string;
  value: number;
  text: string;
}

export const Modeles: FunctionComponent<ModelesProps> = ({
  tModele,
  setModelesSelection,
  tFournisseurSelection,
}) => {
  const [tOption, setOptions] = useState<ModelesOption[]>([]);

  useEffect(() => {
    const getOptions = () => {
      const tOptionItem: ModelesOption[] = [];

      if (tFournisseurSelection.length > 0) {
        tModele
          .filter((m: Modele) => tFournisseurSelection.includes(m.iPKAnnuaire))
          .forEach((m: Modele) => {
            tOptionItem.push({
              key: uuidv4(),
              value: m.iPKProduit,
              text: m.sLibelle + " (" + m.sNom + ")",
            });
          });
      } else {
        tModele.forEach((m: Modele) => {
          tOptionItem.push({
            key: uuidv4(),
            value: m.iPKProduit,
            text: m.sLibelle + " (" + m.sNom + ")",
          });
        });
      }

      setOptions(tOptionItem);
    };

    getOptions();
  }, [tFournisseurSelection, tModele]);

  return (
    <FormElt.Field>
      <FormElt.FieldLabel>
        <FormElt.Label>Modèles :</FormElt.Label>
      </FormElt.FieldLabel>
      <FormElt.FieldContent>
        <FormElt.Droplist
          fluid
          multiple
          search
          selection
          placeholder={
            tOption.length === 0
              ? "Sélectionnez des fournisseurs"
              : "Sélectionnez les modèles"
          }
          options={tOption}
          onChange={(evt: any, data: any) => setModelesSelection(data.value)}
          disabled={tOption.length === 0}
        />
      </FormElt.FieldContent>
    </FormElt.Field>
  );
};
