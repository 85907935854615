// @ts-nocheck
import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { Utilisateur } from "../../../interfaces/utilisateur.interface";
import { deconnexionSucces } from "../utilisateur.slice";

export const deconnexionAction =
  (): ThunkAction<void, Utilisateur, unknown, Action<string>> =>
  async (dispatch) => {
    document.cookie =
      "Authorization=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    dispatch(deconnexionSucces());
  };
