import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BouttonConnexion } from "./deconnexion.elements";

import { deconnexionAction } from "../../../../redux/utilisateur/actions/deconnexion.action";

import AnimatedView from "../../../core/layout/view/mobile/view.elements";

import config from "../../../../config";

const DeconnexionView: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deconnexionAction());
  });

  return (
    <AnimatedView view="Déconnexion">
      <p>Vous êtes déconnecté !</p>

      <BouttonConnexion to="/utilisateur/connexion">Connexion</BouttonConnexion>

      <iframe
        frameBorder="0"
        height="0"
        src={`${config.identityServerURL}/logout`}
        title='Logout'
      />
    </AnimatedView>
  );
};

export default DeconnexionView;
