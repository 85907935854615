// @ts-nocheck
import axios from "axios";
import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";

import { Identite } from "../../../interfaces/utilisateur.interface";
import { connexionSucces, connexionErreur } from "../utilisateur.slice";

import config from "../../../config";

export interface Response {
  user: Identite;
  cookie: string;
}

export const connexionAction =
  (token: string): ThunkAction<void, Identite, unknown, Action<string>> =>
  async (dispatch) => {
    if (!token) {
      dispatch(connexionErreur({ sErreur: "Token non trouvé" }));
    } else {
      try {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        const response = await axios(`${config.serverURL}/auth/verify`, {
          method: "POST",
          data: { token },
          withCredentials: true,
        });

        const {
          data: { user },
        } = response;

        if (!user) {
          throw new Error("Coeexion refusée");
        }

        document.cookie = `Authorization=${token};path=/;secure;SameSite=strict`;

        dispatch(connexionSucces({ user, token: token }));
      } catch (err: any) {
        document.cookie =
          "Authorization=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;secure;SameSite=strict";

        delete axios.defaults.headers.common.Authorization;

        dispatch<any>(connexionErreur({ sErreur: err.message }));
      }
    }
  };
