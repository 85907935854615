import React, { FunctionComponent } from "react";
import FiltersForm from "./filters/form/filters.form"

import { ResultatRecherche } from "../../../../../interfaces/recherche.interface"

interface LeftProps {
  setResultats: (tResultat: ResultatRecherche[]) => void;
  setLoading: (bLoading: boolean) => void;
  bLoading: boolean;
}

const Left: FunctionComponent<LeftProps> = ({ setResultats, setLoading, bLoading }) => {
  return <FiltersForm setLoading={setLoading} bLoading={bLoading} setResultats={setResultats} />
};

export default Left;
