import React, { useState } from "react";
import type {
  CellContextMenuEvent,
  GridColumnsChangedEvent,
  GridReadyEvent,
  RowClickedEvent,
} from "@ag-grid-community/all-modules";
import { AgGridReact } from "@ag-grid-community/react";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import { DateTime } from "luxon";
import styled from "styled-components";

import type { Context } from "../../index.view";
import type { ResultatRecherche } from "../../../../../interfaces/recherche.interface";
import { config } from "../../config";
import Detail from "./elements/detail.elements";
import Loading from "../../../../core/layout/loading/loading.elements";

const GridWrapper = styled.div`
  height: 100%;
  margin: 0px 0 0;
  overflow: hidden;
  width: 100%;
`;

export function alertIcon(niveau: number, variation?: boolean) {
  const colors = [
    "transparent",
    "rgba(147, 198, 83, 1)",
    "rgba(244, 166, 35, 1)",
    "rgba(230, 67, 49, 1)",
  ];
  const color = variation ? "rgba(230, 67, 49, 1)" : colors[niveau];

  return `<svg style="display: inline-block;margin-top: 3px;width: 20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm1.2 15H8.9v-1.9h2.3V15zm0-4.2H8.9V4.6h2.3v6.2z" fill="${color}"/></svg>`;
}

export function colorize(
  value: string,
  criteria: any,
  variation?: boolean
): string {
  let color = "inherit";

  if (variation) {
    if (criteria) {
      color = "rgba(230, 67, 49, 1)";
    }
  } else if (parseInt(criteria, 10) === 2) {
    color = "rgba(244, 166, 35, 1)";
  } else if (parseInt(criteria, 10) === 3) {
    color = "rgba(230, 67, 49, 1)";
  }
  return `<span style="color: ${color};">${value}</span>`;
}

export function formatTableDate(value: string): string {
  return value ? DateTime.fromJSDate(new Date(value)).toFormat("dd/LL/yy") : "";
}

export function formatTableNombre(value: string, variation?: boolean): string {
  if (value) {
    if (variation) return `${Number(value).toFixed(2)}%`;
    return Number(value).toFixed(2);
  }
  if (value === undefined || value === null) {
    value = "";
  }
  return value;
}

interface MainProps {
  bLoading: boolean;
  tResultat: any[] | undefined;
  setAutoResizeFunction: (event: GridReadyEvent) => void;
  setContext: (context: Context) => void;
}

export default function Main({
  bLoading,
  setContext,
  setAutoResizeFunction,
  tResultat,
}: MainProps) {
  const [selectedRow, setSelectedRow] = useState<ResultatRecherche>();

  const handleCellContextMenu = ({ data, event }: CellContextMenuEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    setContext({
      bVisible: true,
      config: config,
      iPKAnnuaire: data.iPKAnnuaire,
      tResultat: [data],
      x: (event as any).clientX,
      y: (event as any).clientY,
    });
  };

  const handleGridReady = (params: GridReadyEvent) => {
    setAutoResizeFunction(params);
    // For the init
    params.api.sizeColumnsToFit();
    // For when the frame is resized
    function reportWindowSize() {
      params.api.sizeColumnsToFit();
    }
    window.onresize = reportWindowSize;
  };

  const handleColumnGroupResized = (params: GridColumnsChangedEvent) => {
    params.api.sizeColumnsToFit();
  };

  const handleRowClick = ({ data }: RowClickedEvent) => {
    const row: ResultatRecherche = data;

    if (row.iPKAnnuaire === selectedRow?.iPKAnnuaire) setSelectedRow(undefined);
    else setSelectedRow(row);
  };

  if (bLoading)
    return (
      <Loading
        bResultat={null}
        sMessage="La recherche peut prendre du temps, merci de patienter."
      />
    );

  if (!tResultat) return null;

  return (
    <>
      <GridWrapper className={`ag-theme-balham ${process.env.theme}`}>
        <AgGridReact
          columnDefs={config.columnDefs}
          defaultColDef={{ resizable: true }}
          localeText={{ noRowsToShow: "Aucun résultat" }}
          modules={AllCommunityModules}
          onCellContextMenu={handleCellContextMenu}
          onGridReady={handleGridReady}
          onRowClicked={handleRowClick}
          onColumnGroupOpened={handleColumnGroupResized}
          rowData={tResultat}
          rowSelection="single"
          suppressAsyncEvents
        />
      </GridWrapper>

      <Detail row={selectedRow} setSelectedRow={setSelectedRow} />
    </>
  );
}
