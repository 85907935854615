import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import config from "../../../../../config";

interface Props {
  children: JSX.Element[] | JSX.Element | null;
  view: string;
}

interface ActionProps {
  action: string;
}

const transition = { ease: "easeInOut", duration: 0.5 };

const View = styled(motion.main)`
  height: 100vh;
  overflow: hidden;
  width: 100vw;
`;

const AnimatedView = (props: Props): JSX.Element => {
  const history = useHistory();

  useEffect(() => {
    document.title = `${props.view} | ${config.name} v${config.version}`;
  }, [props.view]);

  const variants = {
    enter() {
      // if isPush is true, this is the eventual destination of the top page (sliding in from the right)
      // if isPush is false, this is the eventual destination of the bottom page (sliding in from the left)
      const isPush = history.action === "PUSH";
      const staticPosition: "static" = "static";
      const fixedPosition: "fixed" = "fixed";
      /*
      if (isPush) {
        return {
          // x: 0,
          position: fixedPosition,
          width: "100%",
          transform: "translate3d(0, 0, 0)",
          transition,
          transitionEnd: {
            // after animation has finished, reset the position to static
            position: staticPosition,
          },
          // position: fixedPosition,
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        };
      }

      return {
        // x: 0,
        width: "100%",
        transform: "translate3d(0%, 0, 0)",
        transition,
      };
      */

      return {
        x: 0,
        transform: "translate3d(0, 0, 0)",
        transition,
        transitionEnd: {
          // after animation has finished, reset the position to static
          position: staticPosition,
        },
        // keep top "layer" of animation as a fixed position
        ...(isPush
          ? {
              position: fixedPosition,
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }
          : {}),
      };
    },
    initial() {
      // if isPush is true, this is the starting position of top page (sliding in from the right)
      // if ifPush is false, this is the starting position of bottom page (sliding in from the left)
      const isPush = history.action === "PUSH";
      const fixedPosition: "fixed" = "fixed";
      /*
      if (isPush) {
        return {
          // x: "100%",
          zIndex: 1,
          transform: "translate3d(100%, 0, 0)",
          boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
          transition,
          position: fixedPosition,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        };
      }

      return {
        // x: "-25%",
        transform: "translate3d(-25%, 0, 0)",
        boxShadow: "none",
        transition,
      };
      */

      return {
        x: isPush ? "100%" : "-25%",
        boxShadow: isPush ? "0 25px 50px -12px rgba(0, 0, 0, 0.5)" : "",
        transition,
        transform: "translate3d(0, 0, 0)",
        // keep top "layer" of animation as a fixed position
        ...(isPush
          ? {
              position: fixedPosition,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }
          : {}),
      };
    },

    // an updated history.action is provided in AnimatedSwitch via "custom" prop for AnimatePresence
    exit(props: ActionProps) {
      // if isPop is true, this is the top page being dismissed (sliding to the right)
      // if isPop is false, this is the bottom page being dismissed (sliding to the left)
      const isPop = props.action === "POP" || history.location.pathname === "/";
      const fixedPosition: "fixed" = "fixed";
      /*
      if (isPop) {
        return {
          // x: "100%",
          position: fixedPosition,
          zIndex: 1,
          boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.5)",
          transition,
          transform: "translate3d(100%, 0, 0)",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        };
      }

      return {
        // x: "-10%",
        position: fixedPosition,
        transform: "translate3d(-25%, 0, 0)",
        zIndex: -1,
        boxShadow: "none",
        transition,
      };
      */

      return {
        x: isPop ? "100%" : "25%",
        transform: "translate3d(0, 0, 0)",
        zIndex: isPop ? 1 : -1,
        boxShadow: isPop ? "0 25px 50px -12px rgba(0, 0, 0, 0.5)" : "",
        transition,

        // keep top "layer" of animation as a fixed position
        // this will, however, reset the scroll position of the page being dismissed
        ...(isPop
          ? {
              position: fixedPosition,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }
          : {}),
      };
    },
  };

  return (
    <View
      initial="initial"
      animate="enter"
      exit="exit"
      variants={variants}
      {...props}
    >
      {props.children}
    </View>
  );
};

export default AnimatedView;
