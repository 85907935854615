import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import { Button } from "../../button/button.elements";

const StyledToggleButton = styled(Button)<{ dir: string }>`
  position: absolute;
  top: 0;
  ${(props) => (props.dir === "left" ? "right: 0;" : "left: 0;")}
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  color: ${(props) => props.theme.colors.primary.main};
  background: transparent;

  &:hover {
    color: ${(props) => props.theme.colors.primary.hover};
    background: transparent;
  }

  /*
  @media screen and (min-width: 400px) {
    width: 5vw;
  }

  @media screen and (min-width: 800px) {
    width: 3vw;
  }
  */
`;

function ToggleButton({
  dir = "left",
  opened = false,
  onClick = (evt: React.MouseEvent<HTMLElement, MouseEvent>): void => {},
}): JSX.Element {
  return (
    <StyledToggleButton dir={dir} onClick={onClick}>
      {dir === "left" && (
        <Icon
          name={opened ? "angle double left" : "angle double right"}
          size="large"
        />
      )}
      {dir === "right" && (
        <Icon
          name={opened ? "angle double right" : "angle double left"}
          size="large"
        />
      )}
    </StyledToggleButton>
  );
}

export default ToggleButton;
