const formatAppareils = (sMateriel: string): string => {
  if (!sMateriel) {
    return ";";
  }

  let csv: string = "";

  const appareils = sMateriel.split("|");
  appareils.forEach((appareil) => {
    const data = appareil.split(";");
    csv += `${data[0].trim()}|${data[1].trim()}|${data[3]}|${data[4]}|${
      data[2]
    }#`;
  });

  csv = ";" + csv.substring(0, csv.length - 1);

  return csv;
};

export const exportCSV = (tResultat: any[], config: any) => {
  let result: any[] = [];
  tResultat.forEach((tResult) => {
    let firstRow = [];
    let tResultatRow = [];

    //export the corrects headerName from the config instead of the property name, less convenient for users
    for (const [key, value] of Object.entries(tResult)) {
      let headerName: string = "";
      config.columnDefs.forEach(
        (column: { children: any[]; field: string; headerName: string }) => {
          if (column.children) {
            column.children.forEach((child) => {
              if (child.field === key) headerName = child.headerName;
              else if (child.children && child.children.length !== 0) {
                let innerchild = child.children.find(
                  (innerchild: { field: string }) => innerchild.field === key
                );
                if (innerchild) headerName = innerchild.headerName;
              }
            });
          } else if (column.field === key) headerName = column.headerName;
        }
      );
      // create the rows
      if (headerName) {
        firstRow.push(headerName);
        if (value) tResultatRow.push(value);
        else tResultatRow.push("");
      }
    }

    // concat the rows and generate the result
    if (result.length === 0) {
      result.push(firstRow.join(";") + ";Appareils\n");
    }
    const row = tResultatRow.join(";") + formatAppareils(tResult.sMateriel);
    result.push(row + "\n");
  });

  const content = result.join("");

  // parse and export the result in csv
  let csvContent = `data:text/csv;charset=utf-8,\uFEFF${encodeURIComponent(
    content
  )}`;
  let link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", "export.csv");
  document.body.appendChild(link);
  link.click();
};
