import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Context } from "../../../index.view";
import { exportCSV } from "../../../exportCSV";

const MenuWrapper = styled.div<{
  top: number;
  left: number;
  bVisible: boolean;
}>`
  position: fixed;
  z-index: ${(props) => (props.bVisible ? 10 : -1)};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: 200px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.border.dark};
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const ContextLink = styled.a`
  display: block;
  padding: 0 4px;
  line-height: 26px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 11px;
  color: ${(props) => props.theme.text.primary};
  background: rgba(45, 171, 207, 0.1);
  border-bottom: 1px solid ${(props) => props.theme.border.dark};

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.button.primary.background};
  }

  &:last-child {
    border: 0;
  }
`;

const ContextButton = styled.button`
  display: block;
  padding: 0 4px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  line-height: 26px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
  font-size: 11px;
  color: ${(props) => props.theme.text.primary};
  background: rgba(45, 171, 207, 0.1);
  border-bottom: 1px solid ${(props) => props.theme.border.dark};

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.button.primary.background};
  }

  &:last-child {
    border: 0;
  }
`;

interface ContextMenuProps {
  context: Context;
  setContext: (context: Context) => void;
}

const ajusteX = (x: number): number => {
  if (window.innerWidth - x < 210) {
    return window.innerWidth - 210;
  }

  return x;
};

const ajusteY = (y: number): number => {
  if (window.innerHeight - y < 150) {
    return window.innerHeight - 150;
  }

  return y;
};

const ContextMenu: FunctionComponent<ContextMenuProps> = ({
  context,
  setContext,
}): JSX.Element | null => (
  <MenuWrapper
    className="context-menu"
    bVisible={context.bVisible}
    left={ajusteX(context.x)}
    top={ajusteY(context.y)}
  >
    <ContextLink href={`isadomlink://annuaire.${context.iPKAnnuaire}`}>
      Dossier Patient
    </ContextLink>
    <ContextLink href={`isadomlink://annuaireEVT.${context.iPKAnnuaire}`}>
      Actions
    </ContextLink>
    <ContextLink href={`isadomlink://annuaireBLOC.${context.iPKAnnuaire}`}>
      Historique des blocs
    </ContextLink>
    <ContextLink href={`isadomlink://annuaireREL.${context.iPKAnnuaire}`}>
      Détail des relevés
    </ContextLink>
    <ContextButton onClick={() => exportCSV(context.tResultat, context.config)}>
      Télécharger
    </ContextButton>
  </MenuWrapper>
);

export default ContextMenu;
