import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import config from "../../../../../config";

interface Props {
  children?: JSX.Element[] | JSX.Element | null;
  view: string;
}

const View = styled(motion.main)`
  height: 100vh;
  width: 100vw;
`;

const AnimatedView = (props: Props): JSX.Element => {
  useEffect(() => {
    document.title = `${props.view} | ${config.name} v${config.version}`;
  }, [props.view]);

  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0, display: "none" },
  };

  return (
    <View
      initial="initial"
      animate="enter"
      exit="exit"
      variants={variants}
      {...props}
    >
      {props.children}
    </View>
  );
};

export default AnimatedView;
