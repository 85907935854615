import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import ToggleButton from "../elements/togglebutton.element";
import TopBar from "./topbar.elements";

interface LayoutProps {
  topBar?: JSX.Element | null;
  header?: JSX.Element | null;
  left?: JSX.Element | null;
  main?: JSX.Element | null;
  right?: JSX.Element | null;
  leftOpen?: boolean;
  rightOpen?: boolean;
}

const Left = styled.div<{
  opened: boolean;
}>`
  position: absolute;
  height: 94vh;
  top: 6vh;
  overflow: hidden;
  overflow-y: auto;
  border-right: 1px solid ${(props) => props.theme.border.light};
  transition: width 0.3s ease;
  width: ${(props) => (props.opened ? "100vw" : "0vw")};
  background: ${(props) => props.theme.background.default};
  z-index: 5;
  padding-top: 1vh;
  padding-left: ${(props) => (props.opened ? "1vw" : "0vw")};
  padding-right: ${(props) => (props.opened ? "1vw" : "0vw")};
`;

const Right = styled.div<{
  opened: boolean;
}>`
  position: absolute;
  top: 18vh;
  right: 0;
  width: ${(props) => (props.opened ? "100vw" : "0vw")};
  padding-left: 40px;
  background: ${(props) => props.theme.background.default};
  border-left: 1px solid ${(props) => props.theme.border.light};
  transition: width 0.3s ease;
  height: 82vh;
  padding-top: 1vh;
  padding-right: ${(props) => (props.opened ? "1vw" : "0vw")};
`;

const Head = styled.div<{
  leftOpened: boolean;
}>`
  position: relative;
  top: 6vh;
  width: 100vw;
  height: 12vh;
  border-bottom: 1px solid ${(props) => props.theme.border.light};
  transition: transform 0.3s ease;
  background: ${(props) => props.theme.background.default};
`;

const Main = styled.div<{
  leftOpened: boolean;
  rightOpened: boolean;
}>`
  transition: width 0.3s ease;
  position: relative;
  top: 6vh;
  width: 100vw;
  height: 88vh;
  padding-right: 45px;
  padding-top: 1vh;
  padding-left: 1vw;
`;

const Layout: FunctionComponent<LayoutProps> = ({
  header = null,
  left = null,
  main = null,
  right = null,
  leftOpen = false,
  rightOpen = false,
}) => {
  const [leftOpened, setLeftOpened] = useState<boolean>(leftOpen);
  const [rightOpened, setRightOpened] = useState<boolean>(rightOpen);

  const toggleLeft = (evt: TouchEvent): void => {
    evt.preventDefault();

    if (!leftOpened) {
      setLeftOpened(true);
    } else {
      setLeftOpened(false);
    }
  };

  const toggleRight = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault();

    if (!rightOpened) {
      setRightOpened(true);
    } else {
      setRightOpened(false);
    }
  };

  return (
    <div onTouchMove={(e: any) => toggleLeft(e)}>
      {<TopBar />}
      {header && <Head leftOpened={leftOpened}>{header}</Head>}
      {left && <Left opened={leftOpened}>{left}</Left>}
      <Main leftOpened={leftOpened} rightOpened={rightOpened}>
        {main}
      </Main>
      {right && (
        <Right opened={rightOpened}>
          <ToggleButton
            opened={rightOpened}
            onClick={(e: any) => toggleRight(e)}
            dir="right"
          />
          {right}
        </Right>
      )}
    </div>
  );
};

export default Layout;
