import React, { FunctionComponent } from "react";

import * as FormElt from "../../../../../../../core/layout/form/form.elements";

interface Referent {
  iPKAnnuaire: number;
  sNom: string;
  sPrenom: string;
}

interface ReferentsProps {
  tReferent: Referent[];
  setReferentsSelection: (tReferentsSelection: number[]) => void;
}

interface ReferentsOption {
  key: number;
  value: number;
  text: string;
}

export const Referents: FunctionComponent<ReferentsProps> = ({
  tReferent,
  setReferentsSelection,
}) => {
  const options: ReferentsOption[] = [];

  tReferent.forEach((r: Referent) => {
    options.push({
      key: r.iPKAnnuaire,
      value: r.iPKAnnuaire,
      text: `${r.sNom} ${r.sPrenom}`,
    });
  });

  return (
    <FormElt.Field>
      <FormElt.FieldLabel>
        <FormElt.Label>Référents :</FormElt.Label>
      </FormElt.FieldLabel>
      <FormElt.FieldContent>
        <FormElt.Droplist
          fluid
          multiple
          search
          selection
          placeholder={
            tReferent.length === 0
              ? "Chargement des options"
              : "Sélectionnez les référents"
          }
          options={options}
          onChange={(evt: any, data: any) => setReferentsSelection(data.value)}
          disabled={tReferent.length === 0}
        />
      </FormElt.FieldContent>
    </FormElt.Field>
  );
};
