import { Icon } from "semantic-ui-react";
import React from "react";
import styled from "styled-components";

import type { ResultatRecherche } from "../../../../../../interfaces/recherche.interface";
import { Button } from "../../../../../core/layout/button/button.elements";

const Wrapper = styled.div<{ bVisible: boolean }>`
  background: ${(props) => props.theme.background.lighter};
  border-top: 1px solid ${(props) => props.theme.border.dark};
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: ${(props) => (props.bVisible ? 9 : -1)};
`;

const TitleBar = styled.div`
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.border.dark};
  color: ${(props) => props.theme.colors.primary.main};
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding-left: 0.5vmin;
  padding-right: 0.5vmin;
`;

const PatientName = styled.span`
  color: ${(props) => props.theme.text.primary};
  flex-row: 1;
`;

const ButtonClose = styled(Button)`
  height: 20px;
  font-size: 11px;
  line-height: 20px;
  margin: 5px 0;
`;

const DetailBody = styled.div`
  padding-left: 0.5vmin;
  padding-right: 0.5vmin;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

const GridColumn = styled.div`
  grid-row: 1;
`;

const Table = styled.table`
  background-color: ${(props) => props.theme.background.light};
  width: 100%;
`;

const TH = styled.th`
  padding: 1vmin;
`;

const TD = styled.td`
  background-color: ${(props) => props.theme.background.lighter};
  padding: 1vmin;
  text-align: center;
`;

const TableTitle = styled.div`
  font-size: 1em;
  padding: 0.2vmin;
  text-align: center;
`;

const Technicien = styled.span`
  &:after {
    content: ", ";
  }

  &:last-child:after {
    content: "";
  }
`;

interface DetailProps {
  row: any;
  setSelectedRow: (iSelectedRow?: ResultatRecherche) => void;
}


export default function Detail({ row, setSelectedRow }: DetailProps) {
  return <Wrapper bVisible={!!row}>
    {row && (
      <>
        <TitleBar>
          <PatientName>
            <Icon name="user" /> {row.sCodeCivilite} {row.sNom} {row.sPrenom}{" "}
            {" - "}
            {row.sCodeAnnuaire}
          </PatientName>

          <ButtonClose onClick={() => setSelectedRow(undefined)}>
            Fermer
          </ButtonClose>
        </TitleBar>

        <DetailBody>
          <div>
            <TableTitle />
            Technicien{row.sTechnicien?.split("|").length > 1 ? "s" : ""} :{" "}
            {row.sTechnicien?.split("|").map((technicien: string) => (
              <Technicien>{technicien}</Technicien>
            ))}
          </div>

          <Table>
            <thead>
              <tr>
                <TH>Code prescription</TH>
                <TH>Médecin prescripteur</TH>
                <TH>Série de visite</TH>
              </tr>
            </thead>

            <tbody>
              {row.sPrescription?.split("|").map((prescription: string) => {
                const data: string[] = prescription.split(";");
                return (
                  <tr key={data[0]}>
                    <TD>{data[0]}</TD>
                    <TD>{data[1]} {data[2]} {data[3]}</TD>
                    <TD>{data[4]}</TD>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <GridWrapper>
            <GridColumn>
              <TableTitle>Matériel</TableTitle>

              <Table>
                <thead>
                  <tr>
                    <TH>Fournisseur</TH>
                    <TH>Modèle</TH>
                    <TH>Date d'installation</TH>
                  </tr>
                </thead>

                <tbody>
                  {row.sMateriel?.split("|").map((materiel: string) => {
                    const data: string[] = materiel.split(";");
                    return (
                      <tr key={data[0]}>
                        <TD>{data[0]}</TD>
                        <TD>{data[1]}</TD>
                        <TD>{data[2]}</TD>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </GridColumn>

            <GridColumn>
              <TableTitle>
                Masque{row.sMasque?.split("|").length > 1 ? "s" : ""}
              </TableTitle>

              <Table>
                <thead>
                  <tr>
                    <TH>
                      Modèle{row.sMasque?.split("|").length > 1 ? "s" : ""}
                    </TH>
                  </tr>
                </thead>

                <tbody>
                  {row.sMasque?.split("|").map((masque: string) => {
                    const data: string[] = masque.split(";");
                    return (
                      <tr key={data[0]}>
                        <TD>{data[0]}</TD>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </GridColumn>
          </GridWrapper>
        </DetailBody>
      </>
    )}
  </Wrapper>;
};
