import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";
import { App } from "../../interfaces/app.interface";

const initialState: App = {
  bOnline: true,
  sLayoutMode: "desktop",
  sLayoutWidth: window.innerWidth,
  sErreur: "",
};

export const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    layoutSucces: (state, { payload }: PayloadAction<App>) => {
      state.sLayoutMode = payload.sLayoutMode;
      state.sErreur = "";
      state.sLayoutWidth = window.innerWidth;
    },
    layoutErreur: (state, { payload }: PayloadAction<{ sErreur?: string }>) => {
      state.sErreur = payload.sErreur || "";
    },
  },
});

export const { layoutSucces, layoutErreur } = slice.actions;
export const selectLayoutMode = (state: Store) => state.app.sLayoutMode;
export const selectLayoutWidth = (state: Store) => state.app.sLayoutWidth;
export const selectStoreErreur = (state: Store) => state.app.sErreur;

export default slice.reducer;
