import React, { FunctionComponent } from "react";

import * as FormElt from "../../../../../../../core/layout/form/form.elements";

interface ActionEvenement {
  sPKTypeEvt: string;
  sLibelle: string;
}
interface ActionsEnCoursProps {
  tActionsEnCours: ActionEvenement[];
  setActionsEnCoursSelection: (tActionsEnCoursSelection: string[]) => void;
}

interface ActionsEnCoursOption {
  key: string;
  value: string;
  text: string;
}

export const ActionsEnCours: FunctionComponent<ActionsEnCoursProps> = ({
  tActionsEnCours,
  setActionsEnCoursSelection,
}) => {
  const options: ActionsEnCoursOption[] = [];

  tActionsEnCours.forEach((a: ActionEvenement) => {
    options.push({ key: a.sPKTypeEvt, value: a.sPKTypeEvt, text: a.sLibelle });
  });

  return (
    <FormElt.Field>
      <FormElt.FieldLabel>
        <FormElt.Label>Actions en cours :</FormElt.Label>
      </FormElt.FieldLabel>
      <FormElt.FieldContent>
        <FormElt.Droplist
          fluid
          multiple
          search
          selection
          placeholder={
            tActionsEnCours.length === 0
              ? "Chargement des options..."
              : "Sélectionnez les actions"
          }
          options={options}
          onChange={(evt: any, data: any) =>
            setActionsEnCoursSelection(data.value)
          }
          disabled={tActionsEnCours.length === 0}
        />
      </FormElt.FieldContent>
    </FormElt.Field>
  );
};
