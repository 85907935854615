import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

const StyledLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height:100%;
  background-color: rgba(255, 255, 255, 0.66);
  z-index: 1;
`;

const Animation = styled.div`
  text-align: center;
`;

const CircleLight = styled.circle`
  stroke: ${(props) => props.theme.colors.white};
  fill: none;
  stroke-width: 2px;
`;

const CircleDark = styled.circle`
  stroke: ${(props) => props.theme.colors.secondary.main};
  fill: none;
  stroke-width: 2px;
`;

const Message = styled.div`
  margin: 20px 0;
  text-align: center;
  font-size: 16px;
  color: ${(props) => props.theme.colors.secondary.main};
`;

const OK = styled(Icon)`
  color: ${(props) => props.theme.colors.valid.main};
`;

interface Props {
  sMessage?: string;
  bResultat: boolean | null;
}

const Loading = ({ sMessage, bResultat }: Props): JSX.Element => (
  <StyledLoading>
    <Animation>
      {bResultat === null ? (
        <svg
          width="40"
          height="40"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 40"
          preserveAspectRatio="xMidYMid"
        >
          <CircleDark cx="20" cy="20" r="18" />
          <CircleLight cx="20" cy="20" r="18" strokeLinecap="square">
            <animateTransform
              attributeName="transform"
              type="rotate"
              calcMode="linear"
              values="0 20 20;180 20 20;720 20 20"
              keyTimes="0;0.5;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-dasharray"
              calcMode="linear"
              values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
              keyTimes="0;0.5;1"
              dur="1"
              begin="0s"
              repeatCount="indefinite"
            />
          </CircleLight>
        </svg>
      ) : (
        <OK name="check" size="huge" />
      )}
      {sMessage && <Message>{sMessage}</Message>}
    </Animation>
  </StyledLoading>
);

export default Loading;
