import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Store } from "../../interfaces/store.interface";
import { Auth, Identite } from "../../interfaces/utilisateur.interface";

const initialState: Auth = {
  bConnecte: false,
  identite: {
    iPKAnnuaire: 0,
    sCodeAnnuaire: "",
    sCodeCivilite: "",
    sNom: "",
    sPrenom: "",
  },
  sErreur: "",
};

export const slice = createSlice({
  name: "utilisateur",
  initialState,
  reducers: {
    connexionSucces: (
      state,
      { payload }: PayloadAction<{ user: Identite; token: string }>
    ) => {
      state.bConnecte = true;
      state.identite = payload.user;
      state.sErreur = "";
    },
    connexionErreur: (
      state,
      { payload }: PayloadAction<{ sErreur?: string }>
    ) => {
      state.bConnecte = false;
      state.identite = initialState.identite;
      state.sErreur = payload.sErreur || "";
    },
    deconnexionSucces: (state) => {
      state.bConnecte = false;
      state.identite = initialState.identite;
      state.sErreur = "";
    },
  },
});

export const { connexionSucces, connexionErreur, deconnexionSucces } =
  slice.actions;
export const selectConnecte = (state: Store) => state.utilisateur.bConnecte;
export const selectUtilisateur = (state: Store) => state.utilisateur.identite;
export const selectStoreErreur = (state: Store) => state.utilisateur.sErreur;

export default slice.reducer;
