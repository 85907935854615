import {
  alertIcon,
  colorize,
  formatTableDate,
  formatTableNombre,
} from "./elements/main/main.elements";
import type { ResultatRecherche } from "../../../interfaces/recherche.interface";

interface Param {
  data: ResultatRecherche;
  value: any; // FIXME
}

const mapCivilityFullToMin = {
  Docteur: "Dr.",
  Madame: "Mme",
  Mademoiselle: "Mlle",
  Monsieur: "M.",
};

const mapCivilityMinToFull = {
  "Dr.": "Docteur",
  Mlle: "Mademoiselle",
  Mme: "Madame",
  "M.": "Monsieur",
};

const civilityFormatter = (civility: string, displayFull: boolean) => {
  if (!displayFull) {
    for (const [key, value] of Object.entries(mapCivilityFullToMin))
      if (key === civility) return value;
  } else {
    for (const [key, value] of Object.entries(mapCivilityMinToFull))
      if (key === civility) return value;
  }
  return "Indéfinie";
};

const minuteToHoursMinutes = (minutes: number): string => {
  if (isNaN(minutes)) return "";

  const m = minutes % 60;
  const h = (minutes - m) / 60;
  const hhmm = h.toString() + "h" + (m < 10 ? "0" : "") + m.toString();
  return hhmm;
};

export const config = {
  defaultColDef: { resizable: false, sortable: true },
  columnDefs: [
    {
      headerName: "Infos patient",
      children: [
        {
          field: "sCodeAnnuaire",
          headerName: "Code Annuaire",
          cellStyle: { textAlign: "left" },
          sortable: true,
        },
        {
          field: "sCodeCivilite",
          headerName: "Civilité",
          cellStyle: { width: "40px" },
          sortable: true,
          valueFormatter: ({ value }: Param) => civilityFormatter(value, false),
        },
        {
          field: "sNom",
          headerName: "Nom",
          sortable: true,
        },
        {
          field: "sPrenom",
          headerName: "Prénom",
          sortable: true,
        },
        {
          field: "dDateNaissance",
          headerName: "Date de naissance",
          cellStyle: { textAlign: "center" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value }: Param) => formatTableDate(value),
        },
        {
          field: "d1erInstallation",
          headerName: "1er Appareillage",
          cellStyle: { textAlign: "center" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value }: Param) => formatTableDate(value),
        },
        {
          field: "dDerniereReception",
          headerName: "Dernière réception",
          cellStyle: { textAlign: "center" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value }: Param) => formatTableDate(value),
        },
        {
          field: "sAntenne",
          headerName: "Antenne",
          columnGroupShow: "open",
          sortable: true,
        },
        {
          field: "bAlerte",
          headerName: "Alerte",
          cellRenderer: ({ value }: Param) => (value ? alertIcon(3) : ""),
          cellStyle: { textAlign: "center" },
          sortable: true,
        },
      ],
    },
    {
      headerName: "Non transmission",
      children: [
        {
          field: "iNonTransmisStatut",
          headerName: "Statut",
          cellRenderer: ({ value }: Param) => (value ? alertIcon(value) : ""),
          cellStyle: { textAlign: "center" },
          sortable: true,
        },
        {
          field: "iNonTransmisValeur",
          headerName: "Nb jours",
          cellRenderer: ({ data, value }: Param) =>
            value ? colorize(value, data.iNonTransmisStatut) : "",
          cellStyle: { textAlign: "right" },
          columnGroupShow: "open",
          sortable: true,
        },
      ],
    },
    {
      headerName: "Observance",
      children: [
        {
          field: "iObservanceStatut",
          headerName: "Statut",
          cellRenderer: ({ data, value }: Param) =>
            value ? alertIcon(value, data.bObservanceVariation) : "",
          cellStyle: { textAlign: "center" },
          sortable: true,
        },
        {
          field: "iObservancePeriode",
          headerName: "Période (jrs)",
          cellStyle: { textAlign: "right" },
          columnGroupShow: "open",
          sortable: true,
        },
        {
          field: "dObservanceDebut",
          headerName: "Début",
          cellStyle: { textAlign: "center" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value }: Param) => formatTableDate(value),
        },
        {
          field: "dObservanceFin",
          headerName: "Fin",
          cellStyle: { textAlign: "center" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value }: Param) => formatTableDate(value),
        },
        {
          field: "iObservanceValeur",
          headerName: "Durée (hh:mm)",
          cellRenderer: ({ data, value: sMinutes }: Param) => {
            if (parseInt(sMinutes, 10) === 0) {
              return colorize("0h00", data.iObservanceStatut);
            }
            if (!sMinutes) return "";
            const HHMM = minuteToHoursMinutes(parseInt(sMinutes, 10));
            return HHMM ? colorize(HHMM, data.iObservanceStatut) : "";
          },
          cellStyle: { textAlign: "right" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value: sMinutes }: Param) =>
            sMinutes ? minuteToHoursMinutes(parseInt(sMinutes, 10)) : "",
        },
        {
          field: "rObservanceVariation",
          headerName: "Variation (%)",
          sortable: true,
          cellRenderer: ({ data, value }: Param) =>
            value
              ? colorize(
                  formatTableNombre(value, true),
                  data.bObservanceVariation,
                  true
                )
              : "",
          columnGroupShow: "open",
          cellStyle: { textAlign: "right" },
        },
      ],
    },
    {
      headerName: "IAH",
      children: [
        {
          field: "iIAHStatut",
          headerName: "Statut",
          cellRenderer: ({ data, value }: Param) =>
            value ? alertIcon(value, data.bIAHVariation) : "",
          cellStyle: { textAlign: "center" },
          sortable: true,
        },
        {
          field: "iIAHPeriode",
          headerName: "Période (jrs)",
          cellStyle: { textAlign: "right" },
          columnGroupShow: "open",
          sortable: true,
        },
        {
          field: "dIAHDebut",
          headerName: "Début",
          cellStyle: { textAlign: "center" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value }: Param) => formatTableDate(value),
        },
        {
          field: "dIAHFin",
          headerName: "Fin",
          cellStyle: { textAlign: "center" },
          columnGroupShow: "open",
          sortable: true,
          valueFormatter: ({ value }: Param) => formatTableDate(value),
        },
        {
          field: "rIAHValeur",
          headerName: "Valeur",
          cellRenderer: ({ data, value }: Param) =>
            colorize(formatTableNombre(value), data.iIAHStatut),
          cellStyle: { textAlign: "right" },
          columnGroupShow: "open",
          sortable: true,
        },
        {
          field: "rIAHVariation",
          headerName: "Variation (%)",
          cellRenderer: ({ data, value }: Param) =>
            colorize(formatTableNombre(value, true), data.bIAHVariation, true),
          cellStyle: { textAlign: "right" },
          columnGroupShow: "open",
          sortable: true,
        },
        {
          field: "rIAHInitial",
          headerName: "IAH initial",
          cellStyle: { textAlign: "right" },
          columnGroupShow: "open",
          sortable: true,
        },
      ],
    },
    {
      headerName: "Fuites",
      children: [
        {
          headerName: "Moyennes",
          columnGroupShow: "closed",
          children: [
            {
              field: "iFuiteMoyenneStatut",
              headerName: "Statut",
              cellRenderer: ({ data, value }: Param) =>
                value ? alertIcon(value, data.bFuiteMoyenneVariation) : "",
              cellStyle: { textAlign: "center" },
              sortable: true,
            },
            {
              field: "iFuiteMoyennePeriode",
              headerName: "Période (jrs)",
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "dFuiteMoyenneDebut",
              headerName: "Début",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "dFuiteMoyenneFin",
              headerName: "Fin",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "rFuiteMoyenneValeur",
              headerName: "Valeur",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, false),
                  data.iFuiteMoyenneStatut
                ),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "rFuiteMoyenneVariation",
              headerName: "Variation (%)",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, true),
                  data.bFuiteMoyenneVariation,
                  true
                ),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
          ],
        },
        {
          headerName: "Médianes",
          columnGroupShow: "closed",
          children: [
            {
              field: "iFuiteMedianeStatut",
              headerName: "Statut",
              cellRenderer: ({ data, value }: Param) =>
                value ? alertIcon(value, data.bFuiteMedianeVariation) : "",
              cellStyle: { textAlign: "center" },
              sortable: true,
            },
            {
              field: "iFuiteMedianePeriode",
              headerName: "Période (jrs)",
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "dFuiteMedianeDebut",
              headerName: "Début",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "dFuiteMedianeFin",
              headerName: "Fin",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "rFuiteMedianeValeur",
              headerName: "Valeur",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, false),
                  data.iFuiteMedianeStatut
                ),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "rFuiteMedianeVariation",
              headerName: "Variation (%)",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, true),
                  data.bFuiteMedianeVariation,
                  true
                ),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
          ],
        },
        {
          headerName: "90",
          columnGroupShow: "closed",
          children: [
            {
              field: "iFuite90Statut",
              headerName: "Statut",
              cellRenderer: ({ data, value }: Param) =>
                value ? alertIcon(value, data.bFuite90Variation) : "",
              sortable: true,
              cellStyle: { textAlign: "center" },
            },
            {
              field: "iFuite90Periode",
              headerName: "Période (jrs)",
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "dFuite90Debut",
              headerName: "Début",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "dFuite90Fin",
              headerName: "Fin",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "rFuite90Valeur",
              headerName: "Valeur",
              cellRenderer: ({ data, value }: Param) =>
                colorize(formatTableNombre(value, false), data.iFuite90Statut),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "rFuite90Variation",
              headerName: "Variation (%)",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, true),
                  data.bFuite90Variation,
                  true
                ),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
          ],
        },
        {
          headerName: "95",
          columnGroupShow: "closed",
          children: [
            {
              field: "iFuite95Statut",
              headerName: "Statut",
              cellRenderer: ({ data, value }: Param) =>
                value ? alertIcon(value, data.bFuite95Variation) : "",
              cellStyle: { textAlign: "center" },
              sortable: true,
            },
            {
              field: "iFuite95Periode",
              headerName: "Période (jrs)",
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "dFuite95Debut",
              headerName: "Début",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "dFuite95Fin",
              headerName: "Fin",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "rFuite95Valeur",
              headerName: "Valeur",
              cellRenderer: ({ data, value }: Param) =>
                colorize(formatTableNombre(value, false), data.iFuite95Statut),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "rFuite95Variation",
              headerName: "Variation (%)",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, true),
                  data.bFuite95Variation,
                  true
                ),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
          ],
        },

        {
          headerName: "Importantes",
          columnGroupShow: "closed",
          children: [
            {
              field: "iFuiteImportanteStatut",
              headerName: "Statut",
              cellRenderer: ({ data, value }: Param) =>
                value ? alertIcon(value, data.bFuiteImportanteVariation) : "",
              cellStyle: { textAlign: "center" },
              sortable: true,
            },
            {
              field: "iFuiteImportantePeriode",
              headerName: "Période (jrs)",
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
            {
              field: "dFuiteImportanteDebut",
              headerName: "Début",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              field: "dFuiteImportanteFin",
              headerName: "Fin",
              cellStyle: { textAlign: "center" },
              columnGroupShow: "open",
              sortable: true,
              valueFormatter: ({ value }: Param) => formatTableDate(value),
            },
            {
              headerName: "Valeur",
              field: "rFuiteImportanteValeur",
              columnGroupShow: "open",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, false),
                  data.iFuiteImportanteStatut
                ),
              cellStyle: { textAlign: "right" },
              sortable: true,
            },
            {
              field: "rFuiteImportanteVariation",
              headerName: "Variation (%)",
              cellRenderer: ({ data, value }: Param) =>
                colorize(
                  formatTableNombre(value, true),
                  data.bFuiteImportanteVariation,
                  true
                ),
              cellStyle: { textAlign: "right" },
              columnGroupShow: "open",
              sortable: true,
            },
          ],
        },
      ],
    },
    {
      field: "bFuiteImportanteMasque",
      headerName: "Masque Manquant",
      columnGroupShow: "open",
      cellRenderer: ({ data }: Param) =>
        data.bFuite90Masque ||
        data.bFuite95Masque ||
        data.bFuiteImportanteMasque ||
        data.bFuiteMedianeMasque ||
        data.bFuiteMoyenneMasque
          ? alertIcon(3)
          : "",
      cellStyle: { textAlign: "center" },
      sortable: true,
    },
    {
      headerName: "Autre",
      children: [
        {
          field: "sActionEnCours",
          headerName: "Actions en cours",
          sortable: true,
          valueFormatter: ({ value }: Param) => value || "",
        },
      ],
    },
  ],
};
