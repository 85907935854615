import React, { FunctionComponent } from "react";

import  * as FormElt  from "../../../../../../../core/layout/form/form.elements"

interface PatientsSortisProps {
  checked: boolean;
  setPatientsSortis: (bPatientsSortis: boolean) => void;
}

interface PatientsEnAlerteProps {
  checked: boolean;
  setPatientsEnAlerte: (bPatientsEnAlerte: boolean) => void;
}

interface PatientsNouveauxProps {
  checked: boolean;
  setPatientNouveaux: (bPatientNouveaux: boolean) => void;
}

interface PatientsAvecActionsProps {
  checked: boolean;
  setPatientsAvecActions: (bPatientsAvecActions: boolean) => void;
}

export const PatientsSortis: FunctionComponent<PatientsSortisProps> = ({ checked, setPatientsSortis }) => {
  return <FormElt.CheckboxesLine>
      <FormElt.Checkbox 
        id="bPatientsSortis"
        type="checkbox" 
        checked={checked} 
        onChange={(evt) => { setPatientsSortis(evt.currentTarget.checked); }}
      />
    <FormElt.Label htmlFor="bPatientsSortis">Patients sortis</FormElt.Label>
  </FormElt.CheckboxesLine>;
};

export const PatientsEnAlerte: FunctionComponent<PatientsEnAlerteProps> = ({ checked, setPatientsEnAlerte }) => {
  return <FormElt.CheckboxesLine>
      <FormElt.Checkbox
        id="bPatientsEnAlerte"
        type="checkbox" 
        checked={checked} 
        onChange={(evt) => { setPatientsEnAlerte(evt.currentTarget.checked); }}
      />
    <FormElt.Label htmlFor="bPatientsEnAlerte">Patients en alerte</FormElt.Label>
  </FormElt.CheckboxesLine>;
};

export const PatientsNouveaux: FunctionComponent<PatientsNouveauxProps> = ({ checked, setPatientNouveaux }) => {
return <FormElt.CheckboxesLine>
    <FormElt.Checkbox
      id="bPatientNouveaux"
      type="checkbox" 
      checked={checked} 
      onChange={(evt) => { setPatientNouveaux(evt.currentTarget.checked); }}
    />
    <FormElt.Label htmlFor="bPatientNouveaux">Nouveaux patients uniquement</FormElt.Label>
  </FormElt.CheckboxesLine>;
};

export const PatientsAvecActions: FunctionComponent<PatientsAvecActionsProps> = ({ checked, setPatientsAvecActions }) => {
return <FormElt.CheckboxesLine>
    <FormElt.Checkbox
      id="bPatientsAvecActions"
      type="checkbox" 
      checked={checked} 
      onChange={(evt) => { setPatientsAvecActions(evt.currentTarget.checked); }}
    />
    <FormElt.Label htmlFor="bPatientsAvecActions">Exclure les patients ayant des actions en cours</FormElt.Label>
  </FormElt.CheckboxesLine>;
};
