import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ToggleButton from "../elements/togglebutton.element";

const Wrapper = styled.div`
  position: relative;
  background: #fff;
`;

const Top = styled.div<{ topHeight: number; viewWidth: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.viewWidth}px;
  height: ${(props) => props.topHeight}px;
  border-bottom: 1px solid ${(props) => props.theme.border.light};
`;

const Head = styled.div<{
  headHeight: number;
  topHeight: number;
  leftDims: { min: number; max: number };
  leftOpened: boolean;
  viewWidth: number;
}>`
  position: absolute;
  top: ${(props) => props.topHeight}px;
  left: 0px;
  transform: translate3d(
    ${(props) =>
      props.leftOpened ? props.leftDims.max : props.leftDims.min}px,
    0,
    0
  );
  width: ${(props) =>
    props.viewWidth -
    (props.leftOpened ? props.leftDims.max : props.leftDims.min)}px;
  min-width: 800px;
  height: ${(props) => props.headHeight}px;
  border-bottom: 1px solid ${(props) => props.theme.border.light};
  transition: transform 0.3s ease;
`;

const Left = styled.div<{
  leftDims: { min: number; max: number };
  topHeight: number;
  viewHeight: number;
  opened: boolean;
}>`
  position: absolute;
  top: ${(props) => props.topHeight}px;
  left: 0px;
  transform: translate3d(
    ${(props) =>
      !props.opened ? -(props.leftDims.max - props.leftDims.min) : 0}px,
    0,
    0
  );
  width: ${(props) => props.leftDims.max}px;
  height: ${(props) => props.viewHeight - props.topHeight}px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 40px;
  border-right: 1px solid ${(props) => props.theme.border.dark};
  transition: transform 0.3s ease;
`;

const Right = styled.div<{
  rightDims: { min: number; max: number };
  topHeight: number;
  headHeight: number;
  viewHeight: number;
  opened: boolean;
}>`
  position: absolute;
  top: ${(props) => props.topHeight + props.headHeight}px;
  right: 0px;
  transform: translate3d(
    ${(props) =>
      !props.opened ? props.rightDims.max - props.rightDims.min : 0}px,
    0,
    0
  );
  width: ${(props) => props.rightDims.max}px;
  height: ${(props) =>
    props.viewHeight - (props.topHeight + props.headHeight)}px;
  padding-left: 40px;
  background: ${(props) => props.theme.background.default};
  border-left: 1px solid ${(props) => props.theme.border.light};
  transition: transform 0.3s ease;
`;

const Main = styled.div<{
  viewWidth: number;
  viewHeight: number;
  topHeight: number;
  headHeight: number;
  leftDims: { min: number; max: number };
  rightDims: { min: number; max: number };
  leftOpened: boolean;
  rightOpened: boolean;
  right: boolean;
}>`
  position: absolute;
  top: ${(props) => props.topHeight + props.headHeight}px;
  left: 0;
  transform: translate3d(
    ${(props) =>
      props.leftOpened ? props.leftDims.max : props.leftDims.min}px,
    0,
    0
  );
  width: ${(props) =>
    props.viewWidth -
    ((props.leftOpened ? props.leftDims.max : props.leftDims.min) +
      (props.right
        ? props.rightOpened
          ? props.rightDims.max
          : props.leftDims.min
        : 0))}px;
  /* min-width: 800px; */
  height: ${(props) =>
    props.viewHeight - (props.topHeight + props.headHeight)}px;
  transition: transform 0.3s ease;
  overflow: hidden;
  overflow-y: auto;
`;

const Panel = styled.div`
  padding: 40px 0 0 10px;
`;

interface LayoutProps {
  topBar?: JSX.Element | null;
  header?: JSX.Element | null;
  left?: JSX.Element | null;
  main?: JSX.Element | null;
  right?: JSX.Element | null;
  leftOpen?: boolean;
  rightOpen?: boolean;
  onLeft?: Function;
}

interface ViewDimensions {
  width: number;
  height: number;
}

export default function Layout({
  topBar = null,
  header = null,
  left = null,
  main = null,
  right = null,
  leftOpen = false,
  rightOpen = false,
  onLeft,
}: LayoutProps) {
  const topDims = topBar ? { min: 60, max: 60 } : { min: 0, max: 0 };
  const headDims = header ? { min: 60, max: 60 } : { min: 0, max: 0 };
  const leftDims = left ? { min: 40, max: 440 } : { min: 0, max: 0 };
  const rightDims = right ? { min: 40, max: 600 } : { min: 0, max: 0 };

  const [viewDims, setViewDims] = useState<ViewDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [topHeight] = useState<number>(topDims.max);
  const [headHeight] = useState<number>(headDims.max);
  const [leftOpened, setLeftOpened] = useState<boolean>(leftOpen);
  const [rightOpened, setRightOpened] = useState<boolean>(rightOpen);

  const windowResizeHandler = () => {
    setViewDims({ width: window.innerWidth, height: window.innerHeight });
  };

  const toggleLeft = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault();

    if (!leftOpened) {
      setLeftOpened(true);
    } else {
      setLeftOpened(false);
    }
    onLeft?.();
  };

  const toggleRight = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault();

    if (!rightOpened) {
      setRightOpened(true);
    } else {
      setRightOpened(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", windowResizeHandler);

    return () => {
      window.removeEventListener("resize", windowResizeHandler);
    };
  }, []);

  if (viewDims.width < 800) {
    return (
      <div>
        Cette application est optimisée pour un fonctionnement en plein écran
      </div>
    );
  }

  return (
    <Wrapper>
      {topBar && (
        <Top topHeight={topHeight} viewWidth={viewDims.width}>
          {topBar}
        </Top>
      )}
      {left && (
        <Left
          leftDims={leftDims}
          topHeight={topHeight}
          viewHeight={viewDims.height}
          opened={leftOpened}
        >
          <Panel>
            <ToggleButton opened={leftOpened} onClick={toggleLeft} dir="left" />
            {left}
          </Panel>
        </Left>
      )}
      {header && (
        <Head
          headHeight={headHeight}
          topHeight={topHeight}
          leftDims={leftDims}
          leftOpened={leftOpened}
          viewWidth={viewDims.width}
        >
          {header}
        </Head>
      )}
      <Main
        viewWidth={viewDims.width}
        viewHeight={viewDims.height}
        topHeight={topHeight}
        headHeight={headHeight}
        leftDims={leftDims}
        leftOpened={leftOpened}
        right={!!right}
        rightDims={rightDims}
        rightOpened={rightOpened}
      >
        {main}
      </Main>
      {right && (
        <Right
          rightDims={rightDims}
          topHeight={topHeight}
          headHeight={headHeight}
          viewHeight={viewDims.height}
          opened={rightOpened}
        >
          <ToggleButton
            opened={rightOpened}
            onClick={toggleRight}
            dir="right"
          />
          {right}
        </Right>
      )}
    </Wrapper>
  );
}

/*

import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";

import ToggleButton from "../elements/togglebutton.element";

const getMainWidth = (left: boolean, right: boolean, padding: number) => {
  if (left && right) return 40;
  else if (left || right) return 65 + padding;
  else return 90 + 2 * padding;
};

const Head = styled.div<{
  leftOpened: boolean;
}>`
  position: relative;
  top: 5vh;
  left: ${(props) => (props.leftOpened ? "30vw" : "5vw")};
  width: ${(props) => (props.leftOpened ? "70vw" : "100vw")};
  height: 15vh;
  border-bottom: 0.2vh solid ${(props) => props.theme.border.light};
  background: ${(props) => props.theme.background.default};
  padding-left: 1vw;
  padding-top: 1vh;

  @media screen and (min-width: 800px) {
    left: ${(props) => (props.leftOpened ? "30vw" : "3vw")};
  }
`;

const Left = styled.div<{
  opened: boolean;
}>`
  position: absolute;
  top: 5vh;
  left: 0vw;
  transition: width 0.3s ease;
  width: ${(props) => (props.opened ? "30vw" : "0vw")};
  height: 95vh;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5vw;
  padding-top: 1vh;
  padding-left: ${(props) => (props.opened ? "1vw" : "0vw")};
  background: ${(props) => props.theme.background.dark};

  @media screen and (min-width: 800px) {
    padding-right: 3vw;
  }
`;

const Right = styled.div<{
  opened: boolean;
}>`
  position: absolute;
  top: 19.9vh;
  right: 0px;
  width: ${(props) => (props.opened ? "30vw" : "0vw")};
  height: 80vh;
  padding-left: 5vw;
  background: ${(props) => props.theme.background.default};
  border-left: 0.2vh solid ${(props) => props.theme.border.light};
  transition: width 0.3s ease;
  padding-top: 1vh;
  padding-right: ${(props) => (props.opened ? "1vw" : "0vw")};

  @media screen and (min-width: 800px) {
    padding-left: 3vw;
  }
`;

const Main = styled.div<{
  leftOpened: boolean;
  rightOpened: boolean;
}>`
  transition: width 0.3s ease;
  position: relative;
  top: 5vh;
  width: ${(props) =>
    `${getMainWidth(props.leftOpened, props.rightOpened, 0)}vw`};
  left: ${(props) => (props.leftOpened ? "30vw" : "5vw")};
  height: 80vh;
  padding: 1vh;

  @media screen and (min-width: 800px) {
    width: ${(props) =>
      `${getMainWidth(props.leftOpened, props.rightOpened, 2)}vw`};
    left: ${(props) => (props.leftOpened ? "30vw" : "3vw")};
  }
`;

interface LayoutProps {
  topBar?: JSX.Element | null;
  header?: JSX.Element | null;
  left?: JSX.Element | null;
  main?: JSX.Element | null;
  right?: JSX.Element | null;
  leftOpen?: boolean;
  rightOpen?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = ({
  topBar = null,
  header = null,
  left = null,
  main = null,
  right = null,
  leftOpen = false,
  rightOpen = false,
}) => {
  const [leftOpened, setLeftOpened] = useState<boolean>(leftOpen);
  const [rightOpened, setRightOpened] = useState<boolean>(rightOpen);

  const toggleLeft = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault();

    if (!leftOpened) {
      setLeftOpened(true);
    } else {
      setLeftOpened(false);
    }
  };

  const toggleRight = (evt: React.MouseEvent<HTMLElement>): void => {
    evt.preventDefault();

    if (!rightOpened) {
      setRightOpened(true);
    } else {
      setRightOpened(false);
    }
  };

  return (
    <>
      {topBar}
      {header && <Head leftOpened={leftOpened}>{header}</Head>}
      {left && (
        <Left opened={leftOpened}>
          <ToggleButton opened={leftOpened} onClick={toggleLeft} dir="left" />
          {leftOpened && left}
        </Left>
      )}
      <Main leftOpened={leftOpened} rightOpened={rightOpened}>
        {main}
      </Main>
      {right && (
        <Right opened={rightOpened}>
          <ToggleButton
            opened={rightOpened}
            onClick={toggleRight}
            dir="right"
          />
          {right}
        </Right>
      )}
    </>
  );
};

export default Layout;
*/
