import React from "react";

import {
  Droplist,
  Field,
  FieldContent,
  FieldLabel,
  Label,
} from "../../../../../../../core/layout/form/form.elements";

interface Antenne {
  iPKAnnuaire: number;
  sNom: string;
}

interface AntenneSelectProps {
  setAntennesSelection: (tAntennesSelection: number[]) => void;
  tAntenne: Antenne[];
}

export default function AntenneSelect({
  setAntennesSelection,
  tAntenne,
}: AntenneSelectProps) {
  const options = tAntenne.map((a) => ({
    key: a.iPKAnnuaire,
    text: a.sNom,
    value: a.iPKAnnuaire,
  }));

  return (
    <Field>
      <FieldLabel>
        <Label>Antenne :</Label>
      </FieldLabel>

      <FieldContent>
        <Droplist
          clearable
          disabled={tAntenne.length === 0}
          fluid
          multiple
          onChange={(_: any, { value }: any) => setAntennesSelection(value)}
          options={options}
          placeholder={
            tAntenne.length
              ? "Sélectionnez une antenne"
              : "Chargement des options..."
          }
          search
          selection
        />
      </FieldContent>
    </Field>
  );
}
