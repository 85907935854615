import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";

import * as FormElt from "../../../../../../core/layout/form/form.elements";
import {
  PatientsAvecActions,
  PatientsEnAlerte,
  PatientsNouveaux /* PatientsSortis */,
} from "./elements/patients.elements";
import { ActionsEnCours } from "./elements/actions.elements";
import { Fournisseurs } from "./elements/fournisseurs.elements";
import { Modeles } from "./elements/modeles.elements";
import { Prescripteurs } from "./elements/prescripteurs.elements";
import { Referents } from "./elements/referents.elements";
import type { ResultatRecherche } from "../../../../../../../interfaces/recherche.interface";
import AntenneSelect from "./elements/antenne.elements";
import config from "../../../../../../../config";

interface ActionEvenement {
  sLibelle: string;
  sPKTypeEvt: string;
}

interface AntenneInterface {
  iPKAnnuaire: number;
  sNom: string;
}

interface FiltersFormProps {
  bLoading: boolean;
  setLoading: (bLoading: boolean) => void;
  setResultats: (tResultat: ResultatRecherche[]) => void;
}

interface Fournisseur {
  iPKAnnuaire: number;
  sNom: string;
}

interface Modele {
  iPKAnnuaire: number;
  iPKProduit: number;
  sLibelle: string;
  sNom: string;
  sRefProduit: string;
}

interface Parametres {
  tActionEnCours: ActionEvenement[];
  tAntenne: AntenneInterface[];
  tFournisseur: Fournisseur[];
  tModele: Modele[];
  tPrescripteur: Prescripteur[];
  tReferent: Referent[];
}

interface Prescripteur {
  iPKAnnuaire: number;
  sNom: string;
  sPrenom: string;
}

interface Referent {
  iPKAnnuaire: number;
  sNom: string;
  sPrenom: string;
}

export default function FiltersForm({
  setResultats,
  setLoading,
  bLoading,
}: FiltersFormProps) {
  const [bFormLoading, setFormLoading] = useState<boolean>(false);
  const [bPatientsAvecActions, setPatientsAvecActions] =
    useState<boolean>(false);
  const [bPatientsEnAlerte, setPatientsEnAlerte] = useState<boolean>(false);
  const [bPatientsNouveaux, setPatientNouveaux] = useState<boolean>(false);
  const [bPatientsSortis /* setPatientsSortis */] = useState<boolean>(false);
  const [tActionsEnCoursSelection, setActionsEnCoursSelection] = useState<
    string[]
  >([]);
  const [tAntenneSelection, setAntennesSelection] = useState<number[]>([]);
  const [tFournisseurSelection, setFournisseursSelection] = useState<number[]>(
    []
  );
  const [tModeleSelection, setModelesSelection] = useState<number[]>([]);
  const [tPrescripteurSelection, setPrescripteursSelection] = useState<
    number[]
  >([]);
  const [tReferentSelection, setReferentsSelection] = useState<number[]>([]);

  const [parametres, setParametres] = useState<Parametres>({
    tActionEnCours: [],
    tAntenne: [],
    tFournisseur: [],
    tModele: [],
    tPrescripteur: [],
    tReferent: [],
  });

  const fetchConfig = useCallback(async () => {
    try {
      setFormLoading(true);

      const response = await axios(`${config.serverURL}/parametres`, {
        method: "GET",
        withCredentials: true,
      }).catch((err) => {
        throw new Error(err.response.data.sMessage);
      });

      const {
        tAntennes,
        tFournisseurs,
        tModeles,
        tPrescripteurs,
        tReferents,
        tTypesEvenements,
      } = response.data.config;

      setParametres({
        tActionEnCours: tTypesEvenements,
        tAntenne: tAntennes,
        tFournisseur: tFournisseurs,
        tModele: tModeles,
        tPrescripteur: tPrescripteurs,
        tReferent: tReferents,
      });
    } catch (err: any) {
      console.error(err.message);
    } finally {
      setFormLoading(false);
    }
  }, []);

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    try {
      setLoading(true);
      const response = await axios(`${config.serverURL}/recherche`, {
        method: "POST",
        data: {
          bPatActionEnCours: bPatientsAvecActions,
          bPatAlerte: bPatientsEnAlerte,
          bPatNouveau: bPatientsNouveaux,
          bPatSorti: bPatientsSortis,
          tAntenne: tAntenneSelection.length > 0 ? tAntenneSelection : null,
          tListeAction:
            tActionsEnCoursSelection.length > 0
              ? tActionsEnCoursSelection
              : null,
          tListeFournisseur:
            tFournisseurSelection.length > 0 ? tFournisseurSelection : null,
          tListeModele: tModeleSelection.length > 0 ? tModeleSelection : null,
          tListePrescripteur:
            tPrescripteurSelection.length > 0 ? tPrescripteurSelection : null,
          tListeReferent:
            tReferentSelection.length > 0 ? tReferentSelection : null,
        },
        withCredentials: true,
      }).catch((err) => {
        throw new Error(err.response.data.sMessage);
      });

      setResultats(response.data.tResultat);
    } catch (err: any) {
      console.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, [fetchConfig]);

  return (
    <FormElt.Form onSubmit={handleSubmit}>
      <FormElt.Legend>FILTRES DE LA RECHERCHE</FormElt.Legend>

      <FormElt.Field>
        <FormElt.FieldLabel>
          <FormElt.Label>Options des patients :</FormElt.Label>
        </FormElt.FieldLabel>
      </FormElt.Field>

      <FormElt.Field className="inline checkbox">
        <FormElt.FieldContent>
          <FormElt.CheckBoxesGroup>
            <PatientsEnAlerte
              checked={bPatientsEnAlerte}
              setPatientsEnAlerte={setPatientsEnAlerte}
            />

            <PatientsNouveaux
              checked={bPatientsNouveaux}
              setPatientNouveaux={setPatientNouveaux}
            />

            <PatientsAvecActions
              checked={bPatientsAvecActions}
              setPatientsAvecActions={setPatientsAvecActions}
            />
          </FormElt.CheckBoxesGroup>
        </FormElt.FieldContent>
      </FormElt.Field>

      <FormElt.Separator />

      <ActionsEnCours
        setActionsEnCoursSelection={setActionsEnCoursSelection}
        tActionsEnCours={parametres.tActionEnCours}
      />

      <Fournisseurs
        setFournisseursSelection={setFournisseursSelection}
        tFournisseur={parametres.tFournisseur}
      />

      <Modeles
        setModelesSelection={setModelesSelection}
        tFournisseurSelection={tFournisseurSelection}
        tModele={parametres.tModele}
      />

      <AntenneSelect
        setAntennesSelection={setAntennesSelection}
        tAntenne={parametres.tAntenne}
      />

      <Prescripteurs
        setPrescripteursSelection={setPrescripteursSelection}
        tPrescripteur={parametres.tPrescripteur}
      />

      <Referents
        setReferentsSelection={setReferentsSelection}
        tReferent={parametres.tReferent}
      />

      <FormElt.CommandField>
        <FormElt.ButtonSubmitSearch
          disabled={bFormLoading || bLoading}
          sIconName="search"
          sLabel="Rechercher"
        />
      </FormElt.CommandField>
    </FormElt.Form>
  );
}
