import { AppConfig } from "./interfaces/config.interface";

const config: AppConfig = {
  version: process.env.REACT_APP_VERSION || "",
  name: process.env.REACT_APP_NAME || "",

  identityServerURL: process.env.REACT_APP_IDENTITY_SERVER_URL || "",

  theme: process.env.REACT_APP_THEME || "ads",

  serverURL: process.env.REACT_APP_SERVER_URL || "",

  localStorageIdentityKey: process.env.REACT_APP_IDENTITY_KEY || "token",
};

export default config;
